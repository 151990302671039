import { db, default as firebase } from "../firebase"

async function createProject(projectData) {
  let docRef = await db.collection("projects").add(projectData)
  return db
    .collection("users")
    .doc(projectData.teacherID)
    .update({
      pendingProjects: firebase.firestore.FieldValue.arrayUnion(docRef.id),
    })
}

export default createProject

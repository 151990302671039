import { navigate } from "gatsby"
import React, { useState } from "react"
import Button from "react-bootstrap/Button"
import Container from "react-bootstrap/Container"
import Modal from "react-bootstrap/Modal"
import Row from "react-bootstrap/Row"
import "react-datepicker/dist/react-datepicker.css"
import Layout from "../components/utils/layout"
import PickDate from "../components/utils/PickDate"
import SEO from "../components/utils/seo"
import TitleLine from "../components/utils/TitleLine"
import createProject from "../scripts/createProject"
import getUserData from "../scripts/getUserData"
import "../styles/SignUpPage.css"
import { auth, default as firebase } from "../firebase"
import logout from "../scripts/logout"
import InputCreator from "../components/styledInputs/InputCreator"
import NewAsyncButton from "../components/utils/NewAsyncButton"
import dots from "../images/bgs/dots.svg"

class PostATask extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      initialized: false,
      authenticated: null,
      whatWeNeed: "",
      necessarySkills: "",
      postedDates: null,
      teacherEmail: "",
      teacherName: "",
      hours: 0,
      whatWeNeedCount: 0,
      skillsCount: 0,
      howThisWillHelpCount: 0,
      clicked: false,
      modalText: "Creating Project...",
      seriesOne: {
        whatWeNeed: "",
        necessarySkills: "",
        estTime: 0,
        taskName: "",
        howThisWillHelp: "",
      },
      errors: [false, false, false, false, false],
    }
    this.init = this.init.bind(this)
    this.postTask = this.postTask.bind(this)
    this.getTeacherName = this.getTeacherName.bind(this)
    this.setSnapshotStates = this.setSnapshotStates.bind(this)
    this.setClicked = this.setClicked.bind(this)
    this.resetModalState = this.resetModalState.bind(this)
    this.setDate = this.setDate.bind(this)
  }

  init() {
    if (this.state.authenticated) {
      this.setState({ initialized: true })
      let user = auth.currentUser
      getUserData(user.uid)
        .then(data => {
          if (data["userType"] !== "teacher") {
            this.navigateTo()
          }
          this.setSnapshotStates(data)
        })
        .catch(error => {
          console.log(error)
          //TODO ERROR HANDLE
        })
    }
  }

  resetModalState() {
    this.setState({ modalText: "Creating Project..." })
  }
  setClicked() {
    this.setState({ clicked: true })
  }

  setSnapshotStates(snapshot) {
    this.setState({ teacherSchool: snapshot["school"] })
    this.setState({
      teacherName: snapshot["preferredPrefix"]
        ? snapshot["preferredPrefix"] + ". " + snapshot["lastName"]
        : snapshot["prefix"] + ". " + snapshot["lastName"],
      teacherEmail: snapshot["email"],
      teacherFCM: snapshot.fcmToken,
    })
  }

  navigateTo() {
    navigate("/404/")
  }
  getTeacherName() {
    let user = auth.currentUser
    getUserData(user.uid)
      .then(data => {
        this.setSnapshotStates(data)
      })
      .catch(error => {
        console.log(error)
        //TODO ERROR HANDLE
      })
  }

  postTask(outcome) {
    if (
      !this.state.seriesOne.taskName ||
      !this.state.seriesOne.estTime ||
      !this.state.dueDate ||
      !this.state.seriesOne.whatWeNeed ||
      !this.state.seriesOne.necessarySkills ||
      !this.state.seriesOne.howThisWillHelp
    ) {
      outcome("Please fill in all fields. Try Again?")
    } else {
      let user_id = auth.currentUser.uid
      let postData = {
        taskName: this.state.seriesOne.taskName,
        estTime: this.state.seriesOne.estTime,
        teacherName: this.state.teacherName,
        dueDate: firebase.firestore.Timestamp.fromDate(this.state.dueDate),
        whatWeNeed: this.state.seriesOne.whatWeNeed,
        necessarySkills: this.state.seriesOne.necessarySkills,
        howThisWillHelp: this.state.seriesOne.howThisWillHelp,
        teacherID: user_id,
        teacherSchool: this.state.teacherSchool,
        postedDate: firebase.firestore.Timestamp.now(),
        teacherEmail: this.state.teacherEmail,
        studentNames: [],
        studentEmails: [],
        studentSchools: [],
        studentIDs: [],
        signups: [],
        hoursLog: [],
        status: "pending",
        studentFCMs: [],
        teacherFCM: "",
      }
      if (this.state.teacherFCM !== undefined) {
        postData.teacherFCM = this.state.teacherFCM
      }

      createProject(postData)
        .then(() => {
          outcome("Success!")
          // this.setState({ modalText: "Posted!" })
          setTimeout(() => {
            navigate("/teacher-dashboard/")
          }, 750)
        })
        .catch(error => {
          outcome("Error posting project")

          console.log(error)
          //TODO ERROR HANDLE
        })
    }
  }

  componentDidMount() {
    auth.onAuthStateChanged(user => {
      if (!user) {
        this.setState({ authenticated: false })
        // this.navigateTo()
      } else {
        if (!user.emailVerified) {
          logout().then(() => {
            navigate("")
          })
        }
        this.setState({ authenticated: true })
      }
    })
  }

  setDate(date) {
    this.setState({ dueDate: date })
  }

  handleForm = event => {
    let tmp = this.state.seriesOne
    tmp[event.target.name] = event.target.value
    this.setState({ seriesOne: tmp })
    this.resetModalState()
  }

  onFocus = event => {
    const order = [
      "taskName",
      "estTime",
      "whatWeNeed",
      "necessarySkills",
      "howThisWillHelp",
    ]
    let newErrors = this.state.errors
    newErrors[order.indexOf(event.target.name)] = false
    this.setState({ errors: newErrors })
  }

  onBlur = event => {
    const order = [
      "taskName",
      "estTime",
      "whatWeNeed",
      "necessarySkills",
      "howThisWillHelp",
    ]
    let newErrors = this.state.errors
    if (event.target.value === 0 || event.target.value.trim() === "") {
      newErrors[order.indexOf(event.target.name)] = true
    } else if (typeof event.target.value === "string") {
      this.setState({ [event.target.name]: event.target.value.trim() })
    }
    this.setState({ errors: newErrors })
  }

  render() {
    const regularInput = [
      "Project Name",
      "Estimated Completion Time (in hours)",
      "What We Need",
      "Preferred Skills",
      "How This Will Help",
    ]
    const inputType = ["text", "number", "textarea", "textarea", "textarea"]
    const inputNames = [
      "taskName",
      "estTime",
      "whatWeNeed",
      "necessarySkills",
      "howThisWillHelp",
    ]

    if (!this.state.initialized) {
      this.init()
    }
    return (
      <>
        <Container className="topSpacing">
          <>
            <TitleLine
              title="Post a Project"
              description="This is where you can create a project. These are high-level details meant to quickly describe your project to students. Later, once you choose a student to work with, you can email them the comprehensive details of the project"
            />
            <InputCreator
              titles={regularInput}
              errors={this.state.errors}
              types={inputType}
              names={inputNames}
              struct={[1, 1, 1, 1, 1]}
              rows={[1, 1, 4, 4, 4]}
              callback={this.handleForm}
              values={this.state.seriesOne}
              // onBlur={this.onBlur}
              // onFocus={this.onFocus}
            />
            <Container className="justify-content-md-center dueDate">
              <Row className="justify-content-md-center dueDate">
                <span className="dueDateFont">Due Date</span>
              </Row>
              <Row
                className="justify-content-md-center"
                style={{ marginBottom: "20px" }}
              >
                <PickDate setDate={this.setDate} />
              </Row>
            </Container>
            <div className="selector">
              <div class="center">
                <NewAsyncButton
                  buttonTexts={[
                    "Post Project",
                    "Posting Project...",
                    "Error posting project. Try Again?",
                    "Please fill in all fields. Try Again?",
                    "Posted!",
                  ]}
                  buttonVariants={[
                    "primary",
                    "primary",
                    "danger",
                    "danger",
                    "success",
                  ]}
                  buttonColor={["", "", "", "", ""]}
                  callback={this.postTask}
                />
              </div>
            </div>
          </>
        </Container>
      </>
    )
  }
}
const PostTask = () => (
  <Layout image={dots}>
    <SEO title="Post a Task" />
    <PostATask />
  </Layout>
)

export default PostTask
